<template>
<div class="page">
  <var-app-bar title="重置密码" title-position="center" color="#ffffff" text-color="#000" :safe-area-top="true" >
    <template #left>
      <var-button
        color="transparent"
        text-color="#000"
        round
        text
      >
        <var-icon name="chevron-left" :size="24" />
      </var-button>
    </template>
  </var-app-bar>
  <div class="w-full">
    <div class="title">重新设置密码</div>
    <div class="tips">6-90个字符，是用字母、数字，和符号“</div>
    <div class="row">
      <input class="input" :type="showIcon1 ? 'text' : 'password'" placeholder="请输入新密码" v-model="pwd">
      <img v-if="showIcon1" src="@/assets/eye.png" class="icon" alt=""  @click="showIcon1 = false">
      <img v-else src="@/assets/eye1.png" class="icon" alt="" @click="showIcon1 = true">
    </div>
    <div class="row">
      <input class="input" :type="showIcon1 ? 'text' : 'password'" placeholder="请输入新密码" v-model="pwd2">
      <img v-if="showIcon1" src="@/assets/eye.png" class="icon" alt="" @click="showIcon1 = false">
      <img v-else src="@/assets/eye1.png" class="icon" alt="" @click="showIcon1 = true">
    </div>

    <div class="btn">确认</div>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
const pwd = ref('')
const pwd2 = ref('')
const showIcon1 = ref(false)
// const showIcon2 = ref(false)
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  .w-full {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    .title {
      margin-top: 30px;
      color: #333;
      font-family: "Noto Sans SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .tips {
      color: #333;
      font-family: "Noto Sans SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      margin-top: 10px;
    }
    .row {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #E5E5E5;
      height: 40px;
      .input {
        flex: 1;
        outline: none;
        border: none;
        height: 40px;
      }
      .icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.btn {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: #2F88FF;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  color: #fff;
}
</style>
